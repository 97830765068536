export const params = new URLSearchParams(window.location.search);
export const getParams = function (name) {
	return "" + params.get(name);
};
export const getGameSessionId = function () {
	return getParams("roomId");
};

export const isValidURL = str => {
	var pattern = new RegExp(
		"^(https?:\\/\\/)?" + // protocol
			"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
			"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
			"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
			"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
			"(\\#[-a-z\\d_]*)?$",
		"i"
	); // fragment locator
	return !!pattern.test(str);
};

export const urlsForAccess = {
	doc: "https://docs.googleapis.com/$discovery/rest?version=v1",
	sheet: "https://sheets.googleapis.com/$discovery/rest?version=v4",
	docUrl: "https://docs.google.com/document/d/",
	sheetUrl: "https://docs.google.com/spreadsheets/d/",
	slide: "",
	slideUrl: "https://docs.google.com/presentation/d/",
};

export const isValidEmail = email => {
	let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
	return regex.test(email);
};
