<script lang="ts">
	import "./services/database";
	import EmbedScreen from "./pages/EmbedScreen.svelte";
	import UrlScreen from "./pages/UrlScreen.svelte";
	import { dbHost, user, listenOnFirebaseKey, dbOldData, dbUser } from "./services/database";

	import { currentUser, error, isLoading, loadedApi, rePermission, setHost, signedIn, tabs } from "./services/store";
	import SnackBars from "./components/SnackBars.svelte";
	import RePermissionModal from "./components/RePermissionModal.svelte"
	import { onMount } from "svelte";
	import Loader from "./components/Loader.svelte";
	import { getParams } from "./services/utils";
	import { authenticateIfNot } from "./services/gapi";
	import LoginScreen from "./pages/LoginScreen.svelte";
	let host = "";
	let page = "LOADING";
	let isHost = getParams("isHost") === "true";
	$: {
		if (isHost) {
			dbHost.get().then(snap => {
				if (!snap.val()) {
					dbHost.set(user.id);
				}
			});
		}
	}
	$: {
		if (user.id === host) {
			isHost = true;
			dbHost.onDisconnect().remove();
		}
	}
	listenOnFirebaseKey(dbUser, val => {
		currentUser.set(val);
	});
	listenOnFirebaseKey(dbHost, val => {
		setHost(val);
	});

	onMount(async () => {
		dbOldData.on("value", snap => {
			if (snap.exists()) {
				const value = snap.val();
				const filteredValues = Object.keys(value).map(key => ({ ...value[key], key }));
				tabs.set(filteredValues);
				page = "EMBED";
			} else {
				page = "URL";
				tabs.set([]);
			}
		});
		return () => dbOldData.off("value");
	});

	$: host && setHost(host);
</script>

<SnackBars />

{#if $signedIn === false}
	<LoginScreen />
{:else if page === "LOADING"}
	<p>Loading...</p>
{:else if page === "URL"}
	<UrlScreen />
{:else}
	<EmbedScreen />
{/if}
{#if $isLoading}
	<div class="loader-container center" on:click|stopPropagation>
		<Loader />
	</div>
{/if}
{#if $rePermission}
	<RePermissionModal />
{/if}

<style>
	:global(body, html) {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		font-family: "Poppins";
	}
	.loader-container {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background: rgba(106, 105, 105, 0.558);
		backdrop-filter: blur(2px);
		z-index: 22;
		width: 100%;
		height: 100%;
		/* filter: blur(4px); */
	}
	.signin-btn {
		background: #ffffff;
		border: 1px solid #dcdcdc;
		box-sizing: border-box;
		border-radius: 8px;
		padding: 10px 20px 10px 20px;
		min-width: fit-content;
		cursor: pointer;
		gap: 10px;
		height: 100%;
		max-height: 65px;
	}
	.signin-btn:hover {
		border: 1px solid black;
	}
</style>
