<script>
	import { dbURL, user, addToOldData, dbUsers, dbPage } from "../services/database";
	import {
		addPermissions,
		getPermissions,
		handleDocs,
		handleSheets,
		handleSlides,
		openFilePicker,
		authenticateIfNot,
	} from "../services/gapi";

	import { currentUser, error, loadedApi, rePermission, signedIn, turnOffLoading, turnOnLoading } from "../services/store";

	import DocButton from "./DocButton.svelte";
	import GoogleDriveSvg from "./GoogleDriveSvg.svelte";
	import Modal from "./Modal.svelte";
	export let width = "330px";
	// export let placeHolderEmbedUrl = "Add your link here";
	export let onEmbed = () => {};
	let inputUrl = "";
	$: isSignedIn = $signedIn;
	$: isApiLoaded = $loadedApi;

	let isModalOpen = false,
		selectedDocId = "",
		selectedDocImageUrl = "";

	const handlers = {
		Slide: fileTitle => {
			return handleSlides(fileTitle);
		},
		Sheet: fileTitle => {
			return handleSheets(fileTitle);
		},
		Doc: fileTitle => {
			return handleDocs(fileTitle);
		},
	};

	const DocButtons = [
		{
			id: "Doc",
			svgUrl: "/assets/svg/doc.svg",
			bottomText: "New Doc",
		},
		{
			id: "Sheet",
			svgUrl: "/assets/svg/sheet.svg",
			bottomText: "New Sheet",
		},
		{
			id: "Slide",
			svgUrl: "/assets/svg/slide.svg",
			bottomText: "New Slide",
		},
	];

	const handleClick = async idx => {
		try {
			await authenticateIfNot(isSignedIn, isApiLoaded);
		} catch (err) {
			error({ duration: 1000, message: "Please try again, something went wrong!" });
		}

		const docButton = DocButtons[idx];
		if (docButton?.id) {
			isModalOpen = true;
			selectedDocImageUrl = docButton.svgUrl;
			selectedDocId = docButton.id;
		}
	};
	const handleCreate = async fileTitle => {
		const handler = handlers[selectedDocId];
		if (!handler || typeof handler !== "function") return;

		try {
			turnOnLoading();
			const { fileId, fileUrl } = await handler(fileTitle);
			let permissions = [];
			if (fileId) {
				const users = (await dbUsers.get()).val();
				const onlineUsers = [];
				for (let userId in users) {
					if (users[userId].online === true) {
						onlineUsers.push(users[userId]);
					}
				}
				await addPermissions(fileId, onlineUsers);
				permissions = await getPermissions(fileId);
			}
			const addedObject = await addToOldData(fileUrl, fileId, fileTitle, permissions, $currentUser.email);
			await dbURL.set(fileUrl);
			await dbPage.set("EMBED");
			onEmbed(addedObject);
		} catch (err) {
			turnOffLoading();
			error({ duration: 1000, message: "Something went wrong, Please try again!" });
			if (err.status === 403) rePermission.set(true);
		} finally {
			turnOffLoading();
		}
	};

	const handleSelectDrive = async () => {
		try {
			await authenticateIfNot(isSignedIn, isApiLoaded);
			openFilePicker(async data => {
				let owner = [];
				let permissions = [];
				try {
					permissions = await getPermissions(data?.id);
					owner = permissions.filter(permission => permission.role === "owner");
					const addedObject = await addToOldData(
						data?.url,
						data?.id,
						data?.name,
						permissions,
						owner.length ? owner[0]?.emailAddress : null
					);
					await dbURL.set(data?.url);
					onEmbed(addedObject);
				} catch (err) {
					if (err.status === 404) {
						error({ duration: 1000, message: "File not found!" });
					}
				}
			});
		} catch (err) {
			error({ duration: 1000, message: "Please try again, something went wrong!" });
		}
	};
	const closeModal = () => (isModalOpen = false);

	const getHeading = entity => `New Google ${entity}`;
	const getButtonText = entity => `Create new ${entity}`;
	const getPlaceHolder = entity => `New ${entity} Title`;
</script>

<div class="container" style={`width: ${width}`}>
	<!-- <input bind:value={inputUrl} type="url" name="embedUrl" id="urlInput" placeholder={placeHolderEmbedUrl} /> -->
	<!-- <button class="btn" id="embed-button" on:click={handleEmbed}>Embed</button> -->
	<!-- <h1 id="seperator">OR</h1> -->

	<div class="doc-buttons">
		{#each DocButtons as docButton, idx (docButton.id)}
			<DocButton
				imageUrl={docButton.svgUrl}
				bottomText={docButton.bottomText}
				clickHandler={() => handleClick(idx)}
			/>
		{/each}
	</div>
	<button class="btn" id="drive-button" on:click={handleSelectDrive}>
		<span class="select-drive-svg"><GoogleDriveSvg /></span>
		Select from Drive</button
	>
</div>

{#if isModalOpen && isApiLoaded && isSignedIn}
	<Modal
		heading={getHeading(selectedDocId)}
		placeholder={getPlaceHolder(selectedDocId)}
		buttonText={getButtonText(selectedDocId)}
		onCreate={handleCreate}
		onClose={closeModal}
		headingLogo={selectedDocImageUrl}
	/>
{/if}

<style>
	.container {
		display: flex;
		justify-content: stretch;
		flex-direction: column;
		gap: 10px;
		font-family: "Manrope";
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 25px;
		box-sizing: border-box;
	}
	/* #urlInput {
		outline: none;
		border: none;
		border-bottom: 1px solid grey;
	} */
	.doc-buttons {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		min-width: 330px;
		gap: auto;
	}
	/* #embed-button {
		background-color: #6c44a8;
		color: white;
	} */
	#drive-button {
		background-color: white;
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}
	.btn {
		height: 40px;
		border-radius: 8px;
		cursor: pointer;
	}
	.btn:hover {
		border-color: #8d8b8b;
	}
	/* #seperator {
		text-align: center;
		margin: 20px 0;
	} */
	.select-drive-svg {
		margin-right: 8px;
		display: flex;
	}
</style>
