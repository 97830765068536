<script>
    import { rePermission, error } from "../services/store";

    const recheckPermissions = async () => {
        rePermission.set(false);
        try {
            //https://developers.google.com/identity/sign-in/web/incremental-auth#step_2_request_additional_scopes
            const option = new gapi.auth2.SigninOptionsBuilder();
            option.setScope("https://www.googleapis.com/auth/drive");

            const googleUser = await gapi.auth2.getAuthInstance().currentUser.get();
            await googleUser.grant(option);
        } catch (err) {
            console.log(err);
            error({ duration: 1000, message: "something went wrong!" });
        }
    }
</script>

<div class="container">
    <div class="header">
        Sign Up not completed
    </div>
    <div class="content">
        Please give additional access. You can always remove any access later in your Google Account. 
    </div>

    <img class="image" src="/assets/svg/permission.jpeg" alt="Permission">
    <button class="btn" on:click={recheckPermissions}>Check Permission</button>
</div>

<style>
    .container {
        display: flex;
        align-items: center;
        position: fixed;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
		background-color: white;
		font-family: "Manrope";
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 12px;
		padding: 1rem 2rem;
        width: 33rem;
    }

    .image {
        box-shadow: 1px 4px 4px #2f2f2f40;
        border-radius: 12px;
        margin: 1rem;
        height: 31rem;
    }

    .header {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
        font-family: Manrope;
    }

    .content {
        margin-top: 10px;
        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #525252;
        text-align: center;
    }

    .btn {
        display: block;
		margin: 0 auto;
		height: 40px;
		border-radius: 8px;
		cursor: pointer;
		background-color: #6c44a8;
		color: white;
		padding: 5px 20px;
		margin-top: 20px;
    }
</style>
