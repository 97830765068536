import { writable } from "svelte/store";
import { user } from "./database";

let id = 0;

export const signedIn = writable(false);
export const loadedApi = writable(false);
export const rePermission = writable(false);
export const host = writable(null);
export const url = writable("");
export const tabs = writable([]);
export const currentUser = writable({ ...user });
export const isShareWindowOpen = writable(false);
export const openShareFile = () => isShareWindowOpen.set(true);
export const closeShareFile = () => isShareWindowOpen.set(false);

export const isLoading = writable(false);
export const turnOnLoading = () => isLoading.set(true);
export const turnOffLoading = () => isLoading.set(false);

export const snackbars = writable([]);

export const info = ({ duration, message }) => {
	snackbars.update(state => [...state, { id: id++, type: "info", duration, message }]);
};
export const error = ({ duration, message }) => {
	snackbars.update(state => [...state, { id: id++, type: "error", duration, message }]);
};

export const remove = sId => {
	snackbars.update(state => state.filter(s => s.id !== sId));
};

export const setHost = val => host.set(val);
export const setUrl = val => url.set(val);
