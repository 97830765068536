<script>
	import EmbedForm from "../components/EmbedForm.svelte";
	import GoogleDriveSvg from "../components/GoogleDriveSvg.svelte";

	const handlers = {
		sheet: () => {},
		doc: () => {},
		slide: () => {},
	};
</script>

<div id="app">
	<div id="drive-svg" class="center">
		<GoogleDriveSvg />
	</div>
	<h1 id="header">Work Easier Together on Dive!</h1>
	<p class="para">
		Create a new file or access an exisiting one from the drive, edit sharing permissions as needed & enjoy working
		on Dive 🎉 while enjoying all if its perks & features!
	</p>
	<div id="embed-form" class="center">
		<EmbedForm {handlers} />
	</div>
</div>

<style>
	#drive-svg {
		display: flex;
		justify-content: center;
		background: #f6f4f9;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		margin: 0 auto;
	}
	#header {
		font-size: 24px;
		font-weight: 700;
		text-align: center;
		margin-top: 10px;
		font-family: Manrope;
		color: #6c44a8;
	}
	#embed-form {
		margin-top: 50px;
	}
	.para {
		margin-top: 10px;
		width: 550px;
		font-family: "Manrope";
		font-style: normal;
		font-weight: 400;
		font-size: 12.9287px;
		line-height: 18px;
		color: #525252;
		text-align: center;
	}
</style>
