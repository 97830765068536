<script>
	export let imageUrl = "";
	export let bottomText = "New Document";
	export let width = "100px";
	export let height = "100px";
	export let clickHandler = () => {};
</script>

<div class="container" style={`width: ${width};`}>
	<div class="image-container center" style={`height: ${height}`} on:click|stopPropagation={clickHandler}>
		<img class="image" src={imageUrl} alt={bottomText} />
	</div>
	<h1 class="bottom-text">{bottomText}</h1>
</div>

<style>
	/* .container {
	} */
	.image-container {
		cursor: pointer;
		border: 1px solid #dcdcdc;
		border-radius: 8px;
		width: 100%;
	}
	.image-container:hover .image {
		transform: scale(1.05);
		transition: transform 0.1s linear;
	}
	.image-container:hover {
		border-color: #8d8b8b;
	}
	.image {
		width: 60%;
	}
	.bottom-text {
		font-family: "Manrope";
		font-size: 14px;
		font-weight: 600;
		text-align: center;
		margin-top: 8px;
	}
</style>
