<script>
	import { fly } from "svelte/transition";
	import { remove, snackbars } from "../services/store";

	const removeSnackbar = snackbar => {
		setTimeout(() => {
			remove(snackbar.id);
		}, snackbar.duration);
	};
</script>

<div class="snackbars-container center">
	{#each $snackbars as snackbar (snackbar.id)}
		<div class="snackbar" transition:fly={{ y: -200, duration: 500 }} on:introend={() => removeSnackbar(snackbar)}>
			<p>{snackbar["message"]}</p>
		</div>
	{/each}
</div>

<style>
	.snackbars-container {
		position: fixed;
		top: 20px;
		left: 0;
		right: 0;
		flex-direction: column;
		gap: 10px;
		z-index: 20;
	}

	.snackbar {
		box-shadow: 0 3px 10px rgb(0 0 0 / 10%), 0 3px 3px rgb(0 0 0 / 5%);
		padding: 15px;
		border-radius: 8px;
		background-color: white;
	}
</style>
