import * as Sentry from "@sentry/browser";
import App from "./App.svelte";
if (!process.env.EMULATE && (process.env.APP_ENV === "prod" || process.env.APP_ENV === "dev")) {
    console.log("sentry connected");
    Sentry.init({
        dsn: "https://cdbac66cee3b43a785960bef4bd1cea8@o422923.ingest.sentry.io/6363331",
        environment: process.env.APP_ENV,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
const app = new App({
    target: document.body,
});
export default app;
