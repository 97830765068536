<script>
	import { onDestroy, onMount } from "svelte";
	import EmbedForm from "../components/EmbedForm.svelte";
	import Modal from "../components/Modal.svelte";
	import ShareFile from "../components/ShareFile.svelte";
	import Tab from "../components/Tab.svelte";
	import { user, dbOldData, listenOnFirebaseKey, dbUsers } from "../services/database";
	import { openShare } from "../services/gapi";
	import {
		closeShareFile,
		currentUser,
		host,
		isShareWindowOpen,
		setUrl,
		tabs as tabStore,
		turnOffLoading,
		turnOnLoading,
		url,
	} from "../services/store";

	let iFrameNode;
	let onlineUsers = [];
	$: isHost = $host === user.id;

	$: tabs = $tabStore;
	let selectedTabKey = "";
	let selectedTab = "";
	let showAddModal = false;

	const handleTabClick = tab => {
		setUrl(tab?.url);
		selectedTabKey = tab.key;
		selectedTab = tab;
	};
	function isPermission(data) {
		return data?.permissions?.filter(perm => perm.emailAddress === $currentUser?.email).length > 0;
	}
	$: isPermissionToView = isPermission(selectedTab);
	const handleAddClick = event => {
		event.stopPropagation();
		showAddModal = true;
	};
	const handleShare = event => {
		event.stopPropagation();
		if (selectedTab?.fileId) openShare(selectedTab?.fileId);
	};
	const onCloseTab = async (tabToClose, idx) => {
		await dbOldData.child(tabToClose.key).remove();
		if (selectedTabKey === tabToClose.key) {
			selectedTabKey = null;
		}
	};
	const onEmbed = tab => {
		turnOffLoading();
		showAddModal = false;
		selectedTabKey = tab.key;
		setUrl(tab.url);
		selectedTab = tab;
	};

	onMount(() => {
		const tabStoreUnsubscriber = tabStore.subscribe(state => {
			const tab = state?.filter(({ key }) => selectedTabKey === key);
			if (tab.length === 0 && state.length > 0) {
				selectedTabKey = state[0]?.key;
				selectedTab = state[0];
				setUrl(state[0]?.url);
			}
		});

		return () => {
			tabStoreUnsubscriber();
		};
	});
	$: if (selectedTabKey) {
		const tab = $tabStore?.filter(tab => tab?.key === selectedTabKey);
		if (tab) isPermissionToView = isPermission(tab[0]);
		else isPermissionToView = false;
	}

	onDestroy(() => {
		turnOffLoading();
		closeShareFile();
	});

	listenOnFirebaseKey(dbUsers, users => {
		const _onlineUsers = [];
		for (let userId in users) {
			if (users[userId].online === true) {
				_onlineUsers.push(users[userId]);
			}
		}
		onlineUsers = [..._onlineUsers];
	});
	$: isShareBtnVisible = selectedTab?.fileId && selectedTab?.owner === $currentUser?.email;
	$: if (iFrameNode) {
		iFrameNode.addEventListener("load", turnOffLoading);
		iFrameNode.addEventListener("error", turnOffLoading);
	}
	$: $url && isPermissionToView && turnOnLoading();
</script>

<div class="container center">
	<div class="tabs-layer" class:fullWidth={!isShareBtnVisible}>
		<div class="tabs-container">
			<div class="tabs">
				{#each tabs as tab, idx (tab.key)}
					<Tab
						onClose={() => onCloseTab(tab, idx)}
						showCloseButton={isHost || user?.id === tab?.userId}
						onClick={() => handleTabClick(tab)}
						selected={selectedTabKey === tab.key}
					>
						<span>{tab?.title || `Tab ${idx + 1}`}</span>
					</Tab>
				{/each}
			</div>
			<Tab onClick={handleAddClick}>
				<b class="add-icon">+</b>
				<span class="grey-color">Add new doc</span>
			</Tab>
		</div>
		{#if isShareBtnVisible}
			<div class="share-btn">
				<Tab onClick={handleShare} width="100px" isHorizontalPadding={false}>
					<div class="center">
						<img class="heading-logo" src="/assets/svg/share.svg" alt="Lock" />
					</div>
					<span class="grey-color">Share</span>
				</Tab>
			</div>
		{/if}
	</div>
	{#if isPermissionToView}
		<iframe src={$url} title={$url} bind:this={iFrameNode} />
	{:else}
		{turnOffLoading() ? "" : ""}
		<h1 class="no-permissions center">
			You don't have permissions to view this!, please ask the host to share the file
		</h1>
	{/if}
</div>
{#if showAddModal}
	<Modal
		width="420px"
		headingLogo="/assets/svg/g-drive.svg"
		heading="New Doc!"
		onClose={() => (showAddModal = false)}
	>
		<div slot="body" class="center add-modal-body">
			<EmbedForm {onEmbed} />
		</div>
	</Modal>
{/if}
{#if $isShareWindowOpen}
	<ShareFile users={onlineUsers} fileId={selectedTab?.fileId} />
{/if}

<svelte:window on:click={() => (showAddModal = false)} />

<style>
	.container {
		flex-direction: column;
		width: 99vw;
		height: 99vh;
	}
	iframe {
		height: 100%;
		width: 100%;
	}
	.tabs-layer {
		display: grid;
		grid-template-columns: auto 120px;
		column-gap: 10px;
		border-bottom: 1px solid #c5c3c3b0;
		width: 98%;
	}
	.grey-color {
		color: rgba(6, 13, 25, 0.65);
	}
	.fullWidth {
		grid-template-columns: 100%;
	}
	.tabs-container {
		flex: 1;
		display: flex;
		padding: 10px 0;
	}
	.share-btn {
		padding: 10px 10px 10px 0;
	}
	.tabs {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: scroll;
		padding: 0 10px;
		gap: 10px;
	}
	.add-icon {
		font-weight: 700;
		font-size: 20px;
		line-height: 27px;
	}
	.add-modal-body {
		margin-top: 10px;
	}
	.no-permissions {
		width: 100%;
		height: 100%;
	}
</style>
