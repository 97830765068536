<script>
	import { dbOldData } from "../services/database";
	import { addPermissions, getPermissions } from "../services/gapi";
	import { closeShareFile, currentUser, error, turnOffLoading, turnOnLoading } from "../services/store";
	import ListOfOwners from "./ListOfOwners.svelte";

	export let users = [];
	export let fileId = "";

	const handleShareAll = async () => {
		try {
			turnOnLoading();
			await addPermissions(
				fileId,
				users.filter(user => user?.id !== $currentUser?.id)
			);
			const permissions = await getPermissions(fileId);
			await dbOldData.child(fileId)?.child("permissions").set(permissions);
		} catch (err) {
			error({ duration: 1000, message: "Please try again, something went wrong!" });
		} finally {
			turnOffLoading();
		}
	};
</script>

<div class="share-container center">
	<div class="main-body" on:click|stopPropagation>
		<img class="close-modal" src="/assets/svg/cross.svg" alt="Close Modal" on:click={closeShareFile} />

		<h1 class="header center">
			<div class="circular-border center">
				<img class="heading-logo" src="/assets/svg/lock.svg" alt="Lock" />
			</div>
			Share Access
		</h1>
		<div class="list-container">
			<ListOfOwners {users} {fileId} />
		</div>
		<button class="btn" on:click={handleShareAll}>Share with all</button>
	</div>
</div>
<svelte:window on:click={closeShareFile} />

<style>
	.header {
		font-family: "Manrope";
		font-style: normal;
		font-weight: 800;
		font-size: 26px;
		line-height: 24px;
		margin: 30px 0;
		justify-content: flex-start;
	}
	.share-container {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		flex-direction: column;
		gap: 10px;
		z-index: 19;
		background: rgba(106, 105, 105, 0.558);
		backdrop-filter: blur(2px);
	}
	.circular-border {
		display: inline-flex;
		width: 40px;
		height: 40px;
		background: #dad9d994;
		border-radius: 50%;
		margin-right: 15px;
	}
	.heading-logo {
		width: 30px;
		height: 30px;
	}
	.main-body {
		position: relative;
		box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
		padding: 15px 25px;
		border-radius: 8px;
		background-color: white;
		min-width: 450px;
	}
	.close-modal {
		position: absolute;
		top: 12px;
		right: 15px;
		cursor: pointer;
	}
	.list-container {
		padding: 20px 15px;
		max-height: 235px;
		background: #f1f1f1;
		border-radius: 8px;
		overflow-y: scroll;
	}
	.btn {
		cursor: pointer;
		font-family: "Manrope";
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 16px;
		display: flex;
		align-items: center;
		text-align: center;
		position: absolute;
		top: 50px;
		right: 60px;
		border-radius: 52px;
		padding: 10px 17px;
		background: #ffffff;
		border: 1px solid #b8b8b8;
	}
	.btn:hover {
		border-color: black;
	}
</style>
