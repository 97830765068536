<script>
	import GoogleDriveSvg from "../components/GoogleDriveSvg.svelte";
	import { authenticateIfNot } from "../services/gapi";
	import { error, loadedApi, signedIn } from "../services/store";

	const handleSignin = async () => {
		try {
			await authenticateIfNot($signedIn, $loadedApi);
		} catch (err) {
			error({ duration: 1000, message: "Please try again, error while signing in!" });
		}
	};
</script>

<div id="app">
	<div id="drive-svg" class="center">
		<GoogleDriveSvg />
	</div>
	<h1 id="header">Sign In</h1>
	<p class="para">You need to sign in with your Google account in order to use the Google Docs apps!</p>
	<div class="center">
		<button on:click={handleSignin} class="signin-btn center">
			<img src="/assets/svg/google.svg" alt="Google" class="google-svg" />
			Sign in with Google
		</button>
	</div>
</div>

<style>
	#drive-svg {
		display: flex;
		justify-content: center;
		background: #f6f4f9;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		margin: 0 auto;
	}
	#header {
		font-size: 24px;
		font-weight: 700;
		text-align: center;
		margin-top: 10px;
		font-family: Manrope;
		color: #6c44a8;
		margin-top: 20px;
	}
	.para {
		margin-top: 20px;
		width: 300px;
		font-family: "Manrope";
		font-style: normal;
		font-weight: 400;
		font-size: 12.9287px;
		line-height: 18px;
		color: #525252;
		text-align: center;
	}
	.signin-btn {
		width: 300px;
		background: white;
		border-radius: 8px;
		margin-top: 30px;
	}
	.google-svg {
		margin-right: 10px;
	}
</style>
