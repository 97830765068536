<script>
	export let onClick = () => {};
	export let selected = false;
	export let showCloseButton = false;
	export let onClose = () => {};
	export let width = "";
	export let isHorizontalPadding = true;
</script>

<div
	class="tab-container center"
	on:click={selected === false ? onClick : () => {}}
	class:selected
	class:spaceEvenly={showCloseButton}
	class:pr={!showCloseButton}
	class:zeroHorizontalPadding={!isHorizontalPadding}
	style={`width: ${width ? width : null}`}
>
	<slot />
	{#if showCloseButton}
		<img on:click={onClose} class="close-button" src="/assets/svg/cross.svg" alt="Close Tab" />
	{/if}
</div>

<style>
	.zeroHorizontalPadding {
		padding-left: 0;
		padding-right: 0;
	}
	.tab-container {
		background: #ffffff;
		border: 1px solid #dcdcdc;
		box-sizing: border-box;
		border-radius: 8px;
		padding: 3px 10px 3px 20px;
		min-width: fit-content;
		cursor: pointer;
		gap: 10px;
		height: 100%;
		max-height: 65px;
	}
	.pr {
		padding-right: 20px;
	}
	.tab-container:hover:not(.selected) {
		border: 1px solid black;
	}
	.spaceEvenly {
		justify-content: space-evenly;
	}
	.selected {
		background-color: #8a4fe3;
		color: white;
		cursor: default;
	}
	.close-button {
		width: 20px;
		height: 20px;
		border-radius: 50%;
	}
	.close-button:hover {
		cursor: pointer;
		background-color: purple;
	}
	.selected .close-button:hover {
		background-color: black;
	}
</style>
