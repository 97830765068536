<script>
	export let users = [];
	export let fileId = "";
	import { ProfilePicture } from "da-components";
	import { dbOldData, listenOnFirebaseKey } from "../services/database";
	import { addPermission, removePermission } from "../services/gapi";
	import { currentUser, error, turnOffLoading, turnOnLoading } from "../services/store";
	let permissions = [];
	$: dbPermissions = dbOldData.child(fileId)?.child("permissions");
	const handleAllow = user => {
		return async function () {
			try {
				turnOnLoading();
				const newPermissions = await addPermission(fileId, user?.email);
				await dbPermissions.set(newPermissions);
			} catch (err) {
				error({ duration: 1000, message: "Please try again, something went wrong!" });
			} finally {
				turnOffLoading();
			}
		};
	};

	const handleRemove = permission => {
		return async function () {
			try {
				turnOnLoading();
				const newPermissions = await removePermission(fileId, permission?.id);
				await dbPermissions.set(newPermissions);
			} catch (err) {
				error({ duration: 1000, message: "Please try again, something went wrong!" });
			} finally {
				turnOffLoading();
			}
		};
	};
	$: if (fileId && dbPermissions)
		listenOnFirebaseKey(dbPermissions, perm => {
			permissions = perm;
		});
</script>

<div class="permissions-list">
	{#each users as user (user.id)}
		<div class="permissions-container">
			<div class="avatar">
				<ProfilePicture src={user.profilePicture} userName={user.userName} size={36} />
			</div>
			<div class="user-details">
				<h1>{user.userName}</h1>
			</div>
			{#if $currentUser?.id === user?.id}
				<button class="btn">Owner</button>
			{:else if permissions.filter(perm => perm.emailAddress === user.email).length !== 0}
				<button
					class="btn revoke"
					on:click={handleRemove(permissions.filter(perm => perm.emailAddress === user.email)[0])}
				>
					Revoke
				</button>
			{:else}
				<button class="btn share" on:click={handleAllow(user)}>Share</button>
			{/if}
		</div>
	{/each}
</div>

<style>
	.permissions-list {
		min-height: 54px;
	}
	.permissions-container {
		display: flex;
		display: flex;
		gap: 10px;
		align-items: center;
		margin: 10px 0;
	}
	.user-details {
		flex: 1;
	}

	.btn {
		font-family: "Manrope";
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 16px;
		cursor: pointer;
		border-radius: 30px;
		background: #ffffff;
		border: 1px solid #b8b8b8;
		padding: 10px 17px;
	}
	.btn:hover {
		border-color: black;
	}
	.revoke {
		background: #6c44a8;
		color: white;
	}
	.revoke:hover {
		transition: all 0.1s;
		background: white;
		color: black;
	}
</style>
