<script>
	export let heading = "New Google Doc";
	export let onClose = () => {};
	export let onCreate = () => {};
	export let headingLogo = "";
	export let width = "450px";
	export let label = "Name of your document";
	export let placeholder = "New Doc Title";
	export let buttonText = "Create new doc";
	let inputText = "";

	const handleCreate = async () => {
		await onCreate(inputText);
		inputText = "";
		onClose();
	};
</script>

<div class="overlay center">
	<div class="modal-container" style={`width: ${width}`} on:click|stopPropagation>
		<img class="close-modal" src="/assets/svg/cross.svg" alt="Close Modal" on:click={onClose} />
		<slot name="header">
			<h1 class="heading">
				{#if headingLogo}
					<div class="circular-border center">
						<img class="heading-logo" src={headingLogo} alt={heading} />
					</div>
				{/if}
				{heading}
			</h1>
		</slot>
		<slot name="body">
			<div class="form-control">
				<label class="label-name" for="docName">{label}</label>
				<input bind:value={inputText} type="text" name="docName" id="docName" {placeholder} />
			</div>
			<button class="btn" on:click={handleCreate}>{buttonText}</button>
		</slot>
	</div>
</div>
<svelte:window on:click={onClose} />

<style>
	.overlay {
		z-index: 2;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;

		background: rgba(37, 30, 45, 0.82);
	}
	.modal-container {
		box-sizing: border-box;
		background-color: white;
		font-family: "Manrope";
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 12px;
		position: relative;
		padding: 45px 30px 30px;
	}
	.heading {
		font-style: normal;
		font-weight: 800;
		font-size: 26px;
		line-height: 24px;
		display: flex;
		align-items: center;
		margin-bottom: 35px;
	}
	.circular-border {
		display: inline-flex;
		width: 40px;
		height: 40px;
		background: #dad9d994;
		border-radius: 50%;
		margin-right: 15px;
	}
	.heading-logo {
		width: 30px;
		height: 30px;
	}
	.close-modal {
		position: absolute;
		top: 12px;
		right: 15px;
		cursor: pointer;
	}
	.label-name {
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		padding-bottom: 10px;
	}
	.form-control {
		margin-top: 30px;
	}
	#docName {
		padding: 14px 17px;
		border-radius: 8px;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		width: 100%;
	}
	.btn {
		display: block;
		margin: 0 auto;
		height: 40px;
		border-radius: 8px;
		cursor: pointer;
		background-color: #6c44a8;
		color: white;
		padding: 5px 20px;
		margin-top: 20px;
	}
	.btn:hover {
		border-color: #8d8b8b;
	}
</style>
